.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Video Call Styling */
.video-element {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

/* Styling Video overlay elements */
.overlay {
  position: absolute;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.overlay-bottom-right {
  bottom: 10px;
  right: 10px;
}

.overlay-top-right {
  top: 10px;
  right: 10px;
}

.icon {
  margin-right: 5px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Styling Chat elements */
ul.MuiList-root.MuiList-padding.css-1yxt4z4-MuiList-root {
    padding-bottom: 50%;
}

/* Styling Link page elements */
/*...Filter modal...
.MuiBox-root.css-18332s9 {
    margin-top: 30%;
    margin-left: 5%;
    margin-right: 5%;
}*/

/* ...Popup.js Styling... */
/* Styling of removing the popup X button */
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeSmall.css-1e0d89p-MuiButtonBase-root-MuiIconButton-root {
    visibility: hidden;
}
